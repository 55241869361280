<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <span class="textSection1 textGrey" v-html="$t('textSectionLeft1')"></span>
        <span class="textSection1 textBlue" v-html="$t('textSectionLeft2')"></span>
        <span class="textSection1 textGrey" v-html="$t('textSectionLeft3')"></span>
        <span class="textSection1 textBlue" v-html="$t('textSectionLeft4')"></span>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <strong><p class="textSection1 textBlue" v-html="$t('textSectionRight1')"></p></strong> <br>

        <strong><span class="textSection1 textBlue">S1 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight2')"></span> <br>

        <strong><span class="textSection1 textBlue">S2 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight3')"></span> <br>

        <strong><span class="textSection1 textBlue">S3 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight4')"></span> <br>

        <strong><span class="textSection1 textBlue">S4 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight5')"></span> <br>

        <strong><span class="textSection1 textBlue">S5 - </span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight6')"></span> <br> <br>

        <p class="textSection1 textGrey" v-html="$t('textSectionRight7')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row v-bind:justify="mobile ? 'center' : 'start'" v-bind:class="mobile ? 'sectionMobile' : 'section'" style="padding-bottom: 40px;" no-gutters>
      <v-btn v-bind:class="mobile ? 'contactUsBtnMobile' : 'contactUsBtn'"
             rounded
             height="65px"
             dark
             v-html="$t('contactUs')"
             @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'SleepWorkshopComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Sleep workshop sleep well, age well",
    "textSectionLeft1": "Sleep is a physiological and fundamental need. A human being spends about a third of his life sleeping. <br>",
    "textSectionLeft2": "<strong> It has a dual function </strong>: it is both a determinant of good health and an indicator of quality of life. <br> <br>",
    "textSectionLeft3": "From the age of 60, the quality of sleep gradually decreases, it becomes more fragmented due to more frequent awakenings. <br>",
    "textSectionLeft4": "The « Sleep well, live well » workshop aims to raise awareness, inform and educate the elderly on good sleep hygiene and how it works.",
    "textSectionRight1": "The workshops « Sleep well, live well » are built in 5 sessions of 2 hours:",
    "textSectionRight2": "Sleep, its mechanisms and the effects of time - the theoretical part, quiz, sleep diary.",
    "textSectionRight3": "Sleep disorders and their pathologies.",
    "textSectionRight4": "Identification of personalized practical exercises.",
    "textSectionRight5": "Tools for good sleep.",
    "textSectionRight6": "Self-massage-relaxation techniques.",
    "textSectionRight7": "This workshop is for people who want to know the effects of aging on sleep and adopt the right gestures and behaviors favorable to sleep and recovery.",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Atelier sommeil : bien dormir, bien vieillir",
    "textSectionLeft1": "Le sommeil est un besoin physiologique et fondamental. Un être humain passe environ le tiers de sa vie à dormir. <br>",
    "textSectionLeft2": "<strong>Il a une double fonction </strong> : il est à la fois un déterminant d’une bonne santé et un indicateur de qualité de vie. <br><br>",
    "textSectionLeft3": "A partir de 60 ans, la qualité du sommeil diminue peu à peu, il devient plus fragmenté en raison de réveils plus fréquents.<br>",
    "textSectionLeft4": "L’atelier « Dormez bien, vivez bien » a pour vocation de sensibiliser, informer et éduquer le public âgé à une bonne hygiène du sommeil et à connaître son fonctionnement.",
    "textSectionRight1": "Les ateliers « Dormez bien, vivez bien » sont construits en 5 séances de 2h :",
    "textSectionRight2": "Le sommeil, les mécanismes et les effets du temps - la partie théorique, quiz, agenda sommeil.",
    "textSectionRight3": "Troubles du sommeil et ses pathologies.",
    "textSectionRight4": "Identification des exercices pratiques personnalisés.",
    "textSectionRight5": "Outils pour bien dormir.",
    "textSectionRight6": "Automassage-techniques de relaxation.",
    "textSectionRight7": "Cet atelier s’adresse aux personnes qui souhaitent connaitre les effets du vieillissement sur le sommeil et adopter les bons gestes et les comportements favorables au sommeil et à la récupération.",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.section {
  padding-left: 200px;
  padding-right: 200px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 20px;
}

.textSection {
  padding-right: 100px;
}

.contactUsBtn {
  margin-left: 50px;
  font-size: 18px;
  text-transform: none;
  width: 400px;
}

.contactUsBtnMobile {
  font-size: 16px;
  text-transform: none;
  width: 300px;
}

</style>
